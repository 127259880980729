:root {
  --accent-50-color: #ffebee;
  --accent-100-color: #ffcdd2;
  --accent-200-color: #ef9a9a;
  --accent-300-color: #e57373;
  --accent-400-color: #ef5350;
  --accent-500-color: #f44336;
  --accent-600-color: #e53935;
  --accent-700-color: #d32f2f;
  --accent-800-color: #c62828;
  --accent-900-color: #b71c1c;
  --accent-a100-color: #ff8a80;
  --accent-a200-color: #ff5252;
  --accent-a400-color: #ff1744;
  --accent-a700-color: #d50000;
  --gray-50-color: #fafafa;
  --gray-100-color: #f5f5f5;
  --gray-200-color: #eeeeee;
  --gray-300-color: #e0e0e0;
  --gray-400-color: #bdbdbd;
  --gray-500-color: #9e9e9e;
  --gray-600-color: #757575;
  --gray-700-color: #616161;
  --gray-800-color: #424242;
  --gray-900-color: #212121;
  --background-color: var(--gray-100-color);
}

a,
a:hover {
  color: var(--accent-500-color);
}

body {
  background-color: var(--background-color);
  font-family: "Lato", sans-serif;
  font-size: 1.125rem;
  line-height: 1.875rem;
}

h1 {
  margin-top: 48px;
  margin-bottom: 32px;
}

h1:after {
  border-bottom: 3px solid var(--accent-500-color);
  content: "";
  display: block;
  margin-top: 16px;
  width: 50px;
}

h2 {
  margin-top: 24px;
  margin-bottom: 24px;
}

html {
  scroll-behavior: smooth;
}

li,
p {
  max-width: 80ch;
}
